<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Completed Jobs</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Customer Source</label>
                <v-combobox
                    v-model="filter.customerOriginSelected"
                    :items="listCustomerOrigin" 
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Sales Person</label>
                <v-combobox
                    v-model="filter.sellerSelected"
                    :items="listSellers" 
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Status</label>
                <v-combobox
                    v-model="filter.statusSelected"
                    :items="listStatus" 
                    item-text="statusDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'serviceForm'"
                            :showButtons="{
                                edit: item.status === 0 && (userLoggedGetters.id === item.idUserRegister || userLoggedGetters.profile.viewAddAllScheduleService == 1),
                                assignSalesPerson: item.status === 1 && userLoggedGetters.profile.viewAddAllScheduleService == 1
                            }"
                            @assignSalesPerson="assignSalesPerson"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    export default ({

        components: {
            ActionList: ActionList
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: '',
                customerOriginSelected: null,
                sellerSelected: null,
                statusSelected: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Customer", value: "customerName", sortable: true },
                { text: "Customer Source", value: "customerOriginDescription", sortable: true },
                { text: "Sales Person", value: "userNameResponsible", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listService: [],
            listServiceFiltered: [],

            listCustomerOrigin: [],
            listSellers: [],
            listStatus: [],
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listServiceFiltered.filter((serviceFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var startDateFormatted = serviceFilter.startDateFormatted != null && serviceFilter.startDateFormatted != undefined ? serviceFilter.startDateFormatted.toLowerCase().match(filter) : ""
                    var userNameRegister = serviceFilter.userNameRegister != null && serviceFilter.userNameRegister != undefined ? serviceFilter.userNameRegister.toLowerCase().match(filter) : ""
                    var customerName = serviceFilter.customerName != null && serviceFilter.customerName != undefined ? serviceFilter.customerName.toLowerCase().match(filter): ""
                    var customerOriginDescription = serviceFilter.customerOriginDescription != null && serviceFilter.customerOriginDescription != undefined ? serviceFilter.customerOriginDescription.toLowerCase().match(filter): ""
                    var customerPhone = serviceFilter.customerPhone != null && serviceFilter.customerPhone != undefined ? serviceFilter.customerPhone.toLowerCase().match(filter) : ""
                    var estimatedDateFormatted = serviceFilter.estimatedDateFormatted != null && serviceFilter.estimatedDateFormatted != undefined ? serviceFilter.estimatedDateFormatted.toLowerCase().match(filter) : ""
                    var userNameResponsible = serviceFilter.userNameResponsible != null && serviceFilter.userNameResponsible != undefined ? serviceFilter.userNameResponsible.toLowerCase().match(filter) : ""
                    var id = serviceFilter.id.toString().match(filter);

                    if (startDateFormatted != null) { return startDateFormatted; } 
                    else if(userNameRegister != null) { return userNameRegister; } 
                    else if(customerName != null) { return customerName; } 
                    else if(customerOriginDescription != null) { return customerOriginDescription; } 
                    else if(customerPhone != null) { return customerPhone; } 
                    else if(estimatedDateFormatted != null) { return estimatedDateFormatted; }
                    else if(userNameResponsible != null) { return userNameResponsible; }
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__status_pending)';
                        break;

                    case 4:
                        color = 'var(--color__completed)';
                        break;

                    case 5:
                        color = 'var(--color__completed)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async assignSalesPerson() {

                this.showLoading();

                this.getRegisters();

                this.hideLoading();
            },

            async getRegisters() {

                this.listService = await this.$store.dispatch("serviceModule/ListCompletedJobs");
                this.listServiceFiltered = [...this.listService];
                this.listStatus = await this.$store.dispatch("serviceModule/ListStatus");
                this.listCustomerOrigin = await this.$store.dispatch("customerOriginModule/List");
                this.listSellers = await this.$store.dispatch("userModule/ListSalesPerson");
                this.filterRegisters();
            },

            async filterRegisters() {

                let idCustomerOrigin = 0;
                let idSeller = 0;
                let idStatus = 0;
                let listFiltered = [...this.listService];

                if (this.filter.customerOriginSelected != null && this.filter.customerOriginSelected != undefined) {
                    idCustomerOrigin = this.filter.customerOriginSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.idCustomerOrigin == idCustomerOrigin);
                }

                if (this.filter.sellerSelected != null && this.filter.sellerSelected != undefined) {
                    idSeller = this.filter.sellerSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.idUserResponsible == idSeller);
                }

                if (this.filter.statusSelected != null && this.filter.statusSelected != undefined) {
                    idStatus = this.filter.statusSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.status == idStatus);
                }

                this.listServiceFiltered = listFiltered;
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>
